// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'name'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'group_name'
  }, {
    prop: 'title'
  }, {
    prop: 'sub_title'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'rank'
  }, {
    prop: 'tips'
  }, {
    prop: 'page'
  }, {
    prop: 'page_item'
  }, {
    prop: 'page_type'
  }, {
    prop: 'activity_url'
  }, {
    prop: 'begin_date'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'created_at'
  }]
}
